/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.29.4/moment.min.js
 * - /npm/moment-timezone@0.5.37/builds/moment-timezone-with-data-10-year-range.min.js
 * - /npm/lazysizes@5.3.2/plugins/bgset/ls.bgset.min.js
 * - /npm/lazysizes@5.3.2/plugins/rias/ls.rias.min.js
 * - /npm/lazysizes@5.3.2/plugins/respimg/ls.respimg.min.js
 * - /npm/lazysizes@5.3.2/plugins/blur-up/ls.blur-up.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
